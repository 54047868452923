import { default as account8h364gDVhYMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/account.vue?macro=true";
import { default as authyxZr5vODovMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/auth.vue?macro=true";
import { default as callbackACEbed5nM3Meta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/auth0/callback.vue?macro=true";
import { default as beta_45applyALtRZznyI4Meta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/beta-apply.vue?macro=true";
import { default as _91slug_93NOByHl185aMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/book-details/[slug].vue?macro=true";
import { default as guides_45page9PXUB0W6SfMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/guides-page.vue?macro=true";
import { default as _91_46_46_46page_93bJIWO3C7GRMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/gutten-test/[...page].vue?macro=true";
import { default as indexoFLMMH0PKMMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/index.vue?macro=true";
import { default as my_45books3Y1nbjW5lLMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/my-books.vue?macro=true";
import { default as promoPAQUWuyEGIMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/promo.vue?macro=true";
import { default as index36ZoKV4ocUMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/reader/[slug]/index.vue?macro=true";
import { default as sign_45inN6QZ16gIMrMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/sign-in.vue?macro=true";
import { default as signupKudPGhaMikMeta } from "/home/runner/work/e-reader-fe/e-reader-fe/pages/signup.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: account8h364gDVhYMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: authyxZr5vODovMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "auth0-callback",
    path: "/auth0/callback",
    meta: callbackACEbed5nM3Meta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/auth0/callback.vue").then(m => m.default || m)
  },
  {
    name: "beta-apply",
    path: "/beta-apply",
    meta: beta_45applyALtRZznyI4Meta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/beta-apply.vue").then(m => m.default || m)
  },
  {
    name: "book-details-slug",
    path: "/book-details/:slug()",
    meta: _91slug_93NOByHl185aMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/book-details/[slug].vue").then(m => m.default || m)
  },
  {
    name: "guides-page",
    path: "/guides-page",
    meta: guides_45page9PXUB0W6SfMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/guides-page.vue").then(m => m.default || m)
  },
  {
    name: "gutten-test-page",
    path: "/gutten-test/:page(.*)*",
    meta: _91_46_46_46page_93bJIWO3C7GRMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/gutten-test/[...page].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexoFLMMH0PKMMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "my-books",
    path: "/my-books",
    meta: my_45books3Y1nbjW5lLMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/my-books.vue").then(m => m.default || m)
  },
  {
    name: "promo",
    path: "/promo",
    meta: promoPAQUWuyEGIMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: "reader-slug",
    path: "/reader/:slug()",
    meta: index36ZoKV4ocUMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/reader/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45inN6QZ16gIMrMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupKudPGhaMikMeta || {},
    component: () => import("/home/runner/work/e-reader-fe/e-reader-fe/pages/signup.vue").then(m => m.default || m)
  }
]